.info_section {
    color: #ffffff;
    padding:6rem 0;

  }
  
  .info_section p {
    color: #9c9c9c;
  }
  
  .info_section .info_logo h3 {
    text-transform: uppercase;
    font-weight: bold;
  }
  
  .info_section h5 {
    margin-bottom: 25px;
    font-size: 24px;
  }
  
  .info_section .info_contact .img-box {
    width: 35px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  
  .info_section .info_contact p {
    margin: 0;
  }
  
  .info_section .info_contact a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  
  .info_section .info_contact a p {
    color: #9c9c9c;
  }
  
  .info_section .info_contact a:hover p {
    color: #ffffff;
  }
  
  .info_section .info_contact a img {
    height: auto;
    margin-right: 12px;
  }
  
  .info_section .info_links ul {
    padding-left: 15px;
  }
  
  .info_section .info_links ul li {
    list-style-type: none;
    margin: 5px 0;
  }
  
  .info_section .info_links ul li a {
    color: #9c9c9c;
    position: relative;
  }
  
  .info_section .info_links ul li a::before {
    display: none;
    content: "";
    position: absolute;
    left: -15px;
    top: 50%;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    background-color: #d8bf36;
  }
  
  .info_section .info_links ul li a:hover {
    color: #ffffff;
  }
  
  .info_section .info_links ul li.active a {
    color: #ffffff;
  }
  
  .info_section .info_links ul li.active a::before {
    display: block;
  }
  
  .info_section .info_form form {
    margin-top: 20px;
  }
  
  .info_section .info_form form input {
    border: 1px solid #a6adc4;
    background-color: transparent;
    outline: none;
    width: 100%;
    padding: 7px 10px;
    color: #ffffff;
  }
  
  .info_section .info_form form input::-webkit-input-placeholder {
    color: #9c9c9c;
  }
  
  .info_section .info_form form input:-ms-input-placeholder {
    color: #9c9c9c;
  }
  
  .info_section .info_form form input::-ms-input-placeholder {
    color: #9c9c9c;
  }
  
  .info_section .info_form form input::placeholder {
    color: #9c9c9c;
  }
  
  .info_section .info_form form button {
    display: inline-block;
    padding: 8px 30px;
    background-color: #d8bf36;
    color: #ffffff;
    border-radius: 5px;
    margin-top: 15px;
    text-transform: uppercase;
    font-size: 15px;
    border: none;
    width: 100%;
  }
  
  .info_section .info_form form button:hover {
    background-color: #e0cd61;
  }
  
  .info_section .info_form .social_box {
    margin-top: 35px;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  
  .info_section .info_form .social_box a {
    margin-right: 25px;
  }
  /* footer section*/
.footer_section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: relative;
  }
  
  .footer_section p {
    color: #ffffff;
    margin: 0 auto;
    text-align: center;
    padding: 20px;
  }
  
  .footer_section a {
    color: #ffffff;
  }