.hero_area {
    height: 98vh;
    position: relative;
    z-index: 1;
    background: url(./entreprise/bg1.jpg),var(--secondary);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
  }
  
  .footer_bg {
    background-image: url(./entreprise/bg2.jpg);
    background-size: cover;
    background-position: right center;
    position: relative;
    z-index: 1;
  }
 .footer_bg::before{
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--primary);
    opacity: 0.9;
  }
  .heading_container h2 {
    position: relative;
    font-weight: bold;
  }
  .heading_container h2 i {
    color: var(--secondary);
  }
  .heading_container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }