.area {
  background: url(../../entreprise/bg3.jpg),
    linear-gradient(to left, var(--primary), var(--primaryb));
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center center;
  position: relative;
  z-index: 1;
}
.area::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--primary);
  opacity: 0.8;
}
.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  animation: animate 25s linear infinite;
  bottom: -150px;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

.content {
  height: 100vh;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  z-index: 1000;
}
.content .btn1 {
  width: 550px;
  height: 60px;
  font-size: 18px;
  color: white;
  font-weight: 300;
  margin: 5px 0;
  z-index: 200;
}
.btn3 {
  width: 100%;
  height: 60px;
  font-size: 18px;
  color: white;
  font-weight: 300;
  margin: 5px 0;
}
.ar {
  font-family: "almarai-bold", sans-serif;
}
.content .btn1:hover {
  border: #fff 2px solid;
}
.btn3:hover {
  border: #000 2px solid;
}
.textSvg {
  font: 6em/1 Open Sans, Impact;
  text-transform: uppercase;
}
.content img {
  max-width: 250px;
  z-index: 100;
  padding-top: 15px;
}
.txtec-en,
.txtw-en {
  font-size: 3.5rem;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-weight: 900;
  letter-spacing: 3px;
  line-height: 1.8;
}
.txtec-ar,
.txtw-ar {
  font-size: 3.6rem;
  font-family: "almarai-bold", sans-serif;
}
.txtec-en span {
  background: -webkit-linear-gradient(
    360deg,
    var(--secondaryb) 0%,
    var(--secondary) 30%,
    #fff 35%,
    #fff 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.txtec-ar span {
  background: -webkit-linear-gradient(
    180deg,
    var(--secondaryb) 0%,
    var(--secondary) 15%,
    #fff 25%,
    #fff 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.btn2 {
  width: 50px;
  height: 50px;
  color: #fff;
  margin: 0 5px;
}
.btn2:hover {
  border: #fff 2px solid;
}
.instagram {
  background: linear-gradient(
    90deg,
    #feda75,
    #fa7e1e,
    #d62976,
    #962fbf,
    #4f5bd5
  );
}
.location {
  background: linear-gradient(135deg, #c72800, #851c00);
}
.email {
  background: linear-gradient(135deg, #4da6ff, #0077b3);
}
.whatsapp {
  background: #25d366;
}
.divider {
  border-left: 2px solid #fff;
}
