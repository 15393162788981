.about_section{
  background-color: var(--secondary);
  color: #ffffff;
}
.about_section .row {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
            height: 80vh;
  }
  .about_section .img-box img {
    max-width: 100%;
  }
  
  .about_section .detail-box p {
    margin-top: 25px;
  }
  
  .about_section .detail-box a {
    display: inline-block;
    padding: 10px 35px;
    background-color: var(--primary);
    color: #ffffff;
    border-radius: 30px;
    margin: 25px 0 45px 0;
  }
  
  .about_section .detail-box a:hover {
    background-color: var(--primaryb);
  }
  .about_us {
    background-image: url(../../entreprise/bg1.jpg);
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    position: relative;
    z-index: 1;
    min-height: 100vh;
    max-height: 100%;
    width: 100%;
  }
 