:root {
  --primary: #172228;
  --primaryb: rgb(26, 24, 22);
  --secondary: #2d9ca2;
  --secondaryb: #237575;
  --light: #f8f7f2;
  --gray:#e9e3e6;
  --dark: #262B47;
  --text:#ffffff;
}
body {
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;

}

@font-face {
  font-family: 'almarai-bold';
  src: url('./assets/fonts/Almarai-Bold.ttf') format('truetype');
}
.ar{
  font-family: "almarai-bold", sans-serif;
}
#root{
  overflow-x: hidden;
}





