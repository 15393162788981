.work_section{
    padding: 6rem 0;
  background-color: #ffffff;

}

.work_section .heading_container {
    margin-bottom: 45px;
  }
  
  .work_section .row {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  
  .work_section .detail_container .box {
    margin-bottom: 20px;
    padding: 20px;
    -webkit-box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.15);
            box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.15);
  }
  
  .work_section .detail_container .box .top-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  
  .work_section .detail_container .box .top-box .icon-box {
    width: 50px;
    margin-right: 15px;
  }
  
  .work_section .detail_container .box .top-box .icon-box i {
    width: 100%;
  }
  
  .work_section .detail_container .box .top-box h5 {
    font-weight: 600;
    margin: 0;
  }
  
  .work_section .detail_container .box .bottom-box {
    margin-top: 10px;
  }
  
  .work_section .img-box img {
    max-width: 100%;
  }