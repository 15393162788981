.slider_section {
    height: calc(100% - 80px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: relative;
    z-index: 2;
  }
  
  .slider_section .row {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
            width: 100%;
            margin: auto;
            height: 100vh;
  }
  
 
  
  .slider_section .detail_box {
    color: #ffffff;
  }
  
  .slider_section .detail_box h1 {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 30px;
  }
  .slider_section .detail_box p {
    font-size: 1.3rem;
  }
  .slider_section .detail_box .btn-box {
    margin: 30px -5px 0 -5px;
  }
  
  .slider_section .detail_box .btn-box a {
    width: 145px;
    text-align: center;
    margin: 5px;
  }
  
  .slider_section .detail_box .btn-box a.btn-1 {
    display: inline-block;
    padding: 8px 0;
    background-color: var(--secondary);
    color: #ffffff;
    border-radius: 30px;
  }
  
  .slider_section .detail_box .btn-box a.btn-1:hover {
    background-color: var(--secondaryb);
  }
  
  .slider_section .detail_box .btn-box a.btn-2 {
    display: inline-block;
    padding: 8px 0;
    background-color: #ffffff;
    color: #000000;
    border-radius: 30px;
  }
  
  .slider_section .detail_box .btn-box a.btn-2:hover {
    background-color: white;
  }
  
  .slider_section .img-box img {
    width: 100%;
  }
  
  @media (min-width: 768px){
    .crs{
      margin-top: 0;
    }
  }
    @media (max-width: 992px){
      .crs{
        margin-top: -220px;
      }
  }