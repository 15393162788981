.car_section{
  padding: 6rem 0;
  background-color: #ffffff;
}
.cars_section{
  padding: 6rem 0;
}
.car_section  #more {
    display: inline-block;
    padding: 10px 35px;
    background-color: var(--secondary);
    color: #ffffff;
    border-radius: 30px;
    margin: 25px 0 45px 0;
  }

  .car_section  #more:hover {
    background-color: var(--secondaryb);
  }
  
  .car_section .team_container {

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 25px 65px;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  
  .car_section .team_container .box {
    margin: 35px 45px;
    min-width: 200px;
    width: 250px;
  }
  
  .car_section .team_container .box .img-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border-radius: 15px;
  }
  
  .car_section .team_container .box .img-box img {
    width: 100%;
  }
  
  .car_section .team_container .box .detail-box::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -15px;
    left: -10px;
    width: 110%;
    height: 115%;
    border-radius: 25px;
    background-color: var(--secondary);
  }
  .carousel-control-prev .carousel-control-prev-icon{
    background-image: url("/src/assets/images/caret-left-fill.svg");
  }
.carousel-control-next .carousel-control-next-icon{
    background-image: url("/src/assets/images/caret-right-fill.svg");
  }
  .car_section .team_container .box .detail-box {
    margin-top: 15px;
    position: relative;
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  
  .car_section .team_container .box .detail-box h5 {
    font-weight: bold;
    position: relative;
    color: #ffffff;
  }
  
  .car_section .btn-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  
 
  .sign-two {
    font-family: clipneon;
  }
.heart-off {
  animation: heart .1s ease infinite;
  -moz-animation: heart .1s ease infinite;
  -webkit-animation: heart .1s ease infinite;
  -o-animation: heart .1s ease infinite;
  color: var(--secondary);
    text-shadow: 
  -.1vw 0vw .25vw var(--secondary), -.2vw 0vw .25vw var(--secondary), -.4vw 0vw .25vw var(--secondary), 
  -.1vw 0vw 1vw var(--secondaryb), -.2vw 0vw .8vw var(--secondaryb), -.4vw 0vw .8vw var(--secondaryb), .2vw 0vw .5vw var(--secondaryb),
  -.1vw 0vw 10vw var(--secondaryb), -.2vw 0vw 10vw var(--secondaryb), -.4vw 0vw 10vw var(--secondaryb);
}
.row .g-3 i,.card .col i{
  color:var(--secondary)
}
.cars {
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    z-index: 1;
    min-height: 100vh;
    max-height: 100%;
    width: 100%;
    background: url(../../entreprise/bg1.jpg),var(--secondary);
    background-repeat: no-repeat;
    background-position: center top;
}