
.header_section .container-fluid {
    padding-right: 25px;
    padding-left: 25px;
  }
  
  .header_section .nav_container {
    margin: 0 auto;
  }
  
  .custom_nav-container .navbar-nav .nav-item .nav-link {
    padding: 3px 15px;
    margin: 10px 15px;
    color: var(--light);
    text-align: center;
    border-radius: 20px;
  }
  
  .custom_nav-container .navbar-nav .nav-item.active .nav-link, .custom_nav-container .navbar-nav .nav-item:hover .nav-link {
    background-color: var(--secondary);
    color: var(--text);
  }
  
  a,
  a:hover,
  a:focus {
    text-decoration: none;
  }
  
  a:hover,
  a:focus {
    color: initial;
  }
  
  .btn,
  .btn:focus {
    outline: none !important;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  
  
 
  
  .custom_nav-container {
    z-index: 99999;
  }
  
  .navbar-expand-lg .navbar-collapse {
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
  }
  
  .custom_nav-container .navbar-toggler {
    outline: none;
  }
  
  .custom_nav-container .navbar-toggler {
    padding: 0;
    width: 37px;
    height: 42px;
  }
  
  .custom_nav-container .navbar-toggler span {
    display: block;
    width: 35px;
    height: 4px;
    background-color: #ffffff;
    margin: 7px 0;
    -webkit-transition: all .3s;
    transition: all .3s;
  }
  
  .custom_nav-container .navbar-toggler[aria-expanded="true"] .s-1 {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    margin: 0;
    margin-bottom: -4px;
  }
  
  .custom_nav-container .navbar-toggler[aria-expanded="true"] .s-2 {
    display: none;
  }
  
  .custom_nav-container .navbar-toggler[aria-expanded="true"] .s-3 {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    margin: 0;
    margin-top: -4px;
  }
  
  .custom_nav-container .navbar-toggler[aria-expanded="false"] .s-1,
  .custom_nav-container .navbar-toggler[aria-expanded="false"] .s-2,
  .custom_nav-container .navbar-toggler[aria-expanded="false"] .s-3 {
    -webkit-transform: none;
            transform: none;
    

  }

  .custom_nav-container .navbar-toggler {
    border: none;
}
.custom_nav-container {
  background-color: transparent;  
  transition: background-color 0.3s ease-in-out;
  position: fixed;
  top: 0;
  z-index: 100;
}
.custom_nav-container.active {
  background-color: var(--primary);
  opacity: 0.95;
}

.navbar-brand p {
  text-transform: uppercase;
  display: inline-block;
  border-width: 4px 0;
}
.navbar-brand p span {
  font: 700 1.5em/1 "Oswald", sans-serif;
  letter-spacing: 0;
  background: url(https://i.ibb.co/RDTnNrT/animated-text-fill.png) repeat;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

