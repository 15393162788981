.benefit_section {
  color: #ffffff;
  text-align: center;
  padding: 6rem 0;
  position: relative;
}
.benefit_section::after {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: var(--primary);
}
.benefit_section .heading_container {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.benefit_section .benefit_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.benefit_section .benefit_container .box {
  margin: 25px 10px;
  width: 350px;
}

.benefit_section .benefit_container .box .img-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 200px;
  background-color: #ffffff;
  border-radius: 15px;
}

.benefit_section .benefit_container .box .img-box img {
  width: 8.4rem;
}
.benefit_section .benefit_container .box .img-box:nth-child(2) img {
  height: 6rem;
  width: 6rem;
}

.benefit_section .benefit_container .box .detail-box {
  margin-top: 15px;
}

.benefit_section .benefit_container .box .detail-box h5 {
  font-weight: bold;
  position: relative;
}

.benefit_section .btn-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.benefit_section .btn-box a {
  display: inline-block;
  padding: 10px 35px;
  background-color: var(--secondary);
  color: #ffffff;
  border-radius: 30px;
}

.benefit_section .btn-box a:hover {
  background-color: var(--secondaryb);
}
@media (max-width: 600px) {
  .benefit_section .benefit_container .box {
    margin: 25px 10px;
    width: 100%;
  }
}
